import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4×8\\@70%1RM`}</p>
    <p>{`Barbell Rows 4×8\\@70%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 each:`}</p>
    <p>{`S2OH (115/75)(RX+ 135/95)`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`Burpees Over Rower`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      